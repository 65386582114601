<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{  'ad-type-property': adtype === 'property',
  }">
    <div class="ad-update-container row">
      <div class="pb-2" v-if="date">
          <div class="date-added">
              <div>{{ $t('added-on') }}:</div>
              <div>{{ date }}</div>
          </div>
          <div class="date-added mt-4 mb-3" v-if="vipEndDate">
              <div>{{ $t('vip-until') }}:</div>
              <div>{{ vipEndDate }}</div>
          </div>
      </div>
      <div class="" v-if="adtype === 'property'">
        <div class="" v-if="propertyid">
          {{ $t('offer-id') }}: {{ propertyid }}
        </div>
        <div class="toggle-container d-flex mt-4">
          <span class="pe-3">{{ $t('visible') }}:</span>
          <ToggleSwitch class="toggle-switch ms-auto" @change="handleStatusSwitch" :checked="status"/>
        </div>
        <div class="toggle-container d-flex mt-2 mb-4">
          <span class="pe-3">{{ $t('pet-friendly') }}:</span>
          <ToggleSwitch class="toggle-switch ms-auto" @change="handlePetFriendlySwitch" :checked="petFriendly"/>
        </div>
        <div>
          <div class="pe-2">
            <div>{{ $t('price-in-eur') }}:</div>
            <InputField @input="handleInput" v-model="regularPrice" :content="regularPrice" :label="$t('enter-price')"
                        :inputtype="'number'" :error="formErrors.includes('price')"/>
          </div>
          <div class="pe-2 mt-3">
            <div>{{ $t('deposit-in-eur') }}:</div>
            <InputField @input="handleDepositInput" v-model="depositAmount" :content="depositAmount" :label="$t('enter-deposit')"
                        :inputtype="'number'" :error="formErrors.includes('deposit')"/>
          </div>
          <div class="col-6 ps-2" v-if="false">
            <div>Валута:</div>
            <Dropdown :option1="'BGN'" :option2="'EUR'"/>
          </div>
        </div>

      </div>
      <!--      <div class="pt-4">-->
      <!--        <Button :size="'large'" :theme="'solid-green'" :text="'Запази'" />-->
      <!--      </div>-->
    </div>
  </div>
</template>
